import React, {useState} from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../../components/Seo"
// import Search from "../../components/Algolia"
import AllFilms from "../../components/AllFilms"
import { Helmet } from 'react-helmet'

// const d = new Date();
// const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const genres = [
  "Action",
  "Adventure",
  "Animation",
  "Children/Family",
  "Comedy",
  "Crime",
  "Documentary",
  "Drama",
  "Experimental",
  "Fantasy",
  "Horror",
  "Musical",
  "Music Video",
  "Mystery",
  "Romance",
  "Sci-Fi",
  "Thriller",
  "Western",
  "Other"
]

const FilmLibrary = () => {
  const [genreSelected, setGenreSelected] = useState("");
  let updatedGenreList = [...genreSelected]

  const handleGenreSelection = (event) => {
      setGenreSelected(event.target.value);
  }
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Film Library</title>
        <link rel="canonical" href="https://filmchronicle.com/watch/film-library" /> 
      </Helmet>
      <Seo title="Film Library" description="Browse our extensive collection of student films." image="../../../assets/images/Watch-Films__Film-Library-Hero.png" />
      <header className="hero">
        <StaticImage
          src="../../assets/images/Watch-Films__Film-Library-Hero.png"
          alt="flour and eggs"
          className="hero-img"
          placeholder="tracedSVG"
          layout="fullWidth"
        ></StaticImage>
        <div className="hero-container">
          <div className="hero-text">
            <h1>Film Library</h1>
          </div>
        </div>
      </header>
      <div className="container-lg">
        <div className="section" style={{paddingBottom: "1rem", justifyContent: "space-between"}}>
            <div className="hideMe">
              {/* <h2 style={{fontSize: "30px"}}>{months[d.getMonth()]} {new Date().getFullYear()} Playlist</h2> */}
              <h2 style={{fontSize: "30px"}}>Archived Films</h2>
              <p style={{fontSize: "20px"}}>Explore films by genre!</p>
            </div>
            <div className="filter" style={{paddingBottom: "1rem"}}>
              <select style={{border: "2px solid #232324", padding: "16px 16px", backgroundColor: "#EAEAEE"}} value={genreSelected} onChange={handleGenreSelection}>
              {genres.map((item, index) => (
                <option style={{display: "flex", gap: "1rem", fontFamily: "Proxima Nova, sans-serif"}} key={index}>
                  {item}
                </option>
              ))}
              </select>
            </div>
        </div>
              {/* <Search/> */}
              <AllFilms genres={genreSelected}/>
      </div>
    </Layout>
  )
}

export default FilmLibrary
